<script>
import VueWithCompiler from 'vue/dist/vue.esm'

export default {
  name: 'CustomHtmlComponent',
  props: {
    html: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
    },
  },
  data() {
    return { templateRender: undefined }
  },
  watch: {
    html() {
      this.updateRender()
    },
  },
  created() {
    this.updateRender()
  },
  methods: {
    updateRender() {
      const compiled = VueWithCompiler.compile(this.html)
      this.templateRender = compiled.render
      this.$options.staticRenderFns = []
      // eslint-disable-next-line no-restricted-syntax
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction)
      }
    },
    sendEvent(event) {
      this.$emit(event)
    },
  },
  render() {
    if (!this.templateRender) return this.$createElement('div')
    return this.templateRender()
  },
}
</script>

<style scoped>

</style>
